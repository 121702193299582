/**
 * Parse et returns UTC Date (YYYYmmddTHis)
 * @param {string} apiDate
 * @returns {Date} The parsed date
 */
export const parseUTCDate = (apiDate) => {
  // Split to have ['Y', 'Y', 'Y', 'Y', 'm', 'm', ... ]
  const utcDate = apiDate.split('')
  // At index 4, insert '-'
  utcDate.splice(4, 0, '-')
  utcDate.splice(7, 0, '-')
  utcDate.splice(13, 0, ':')
  utcDate.splice(16, 0, ':')
  // .join adds all elements into a string and returns Date look like '2023-10-19T17:42:00'
  return new Date(utcDate.join(''))
}

/**
 * Formats the the minutes of a Date object et returns them
 * @param {Date} date
 * @returns {string} The full minutes addind a '0' if minutes < 10
 */
export const getFullMinutes = (date) => {
  if (date.getMinutes() < 10) {
    return `0${date.getMinutes()}`
  }
  return date.getMinutes()
}

/**
 * Calcultes the the delay between 2 Date objects
 * @param {Date} baseDepartureTime The base departure time
 * @param {Date} realDepartureTime The real departure time
 * @returns {string} "A l'heure" if not delay or the delay between baseDepartureTime and realDepartureTime
 */
export const calculateDelay = (baseDepartureTime, realDepartureTime) => {
  if (baseDepartureTime.getTime() !== realDepartureTime.getTime()) {
    const minutesDelay =
      (realDepartureTime.getTime() - baseDepartureTime.getTime()) / (1000 * 60)
    if (minutesDelay >= 60) {
      return `retard ${Math.floor(minutesDelay / 60)}h${minutesDelay % 60}`
    }
    return `R. : ${minutesDelay} min`
  }
  return "À l'heure"
}
