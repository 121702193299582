import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { parseUTCDate, getFullMinutes, calculateDelay } from './Utils'
import Stops from './Stops'

function Departures() {
  const { codeStation } = useParams()

  const [nextDepartures, setNextDepartures] = useState([])

  useEffect(async () => {
    await axios
      .get(
        `https://api.sncf.com/v1/coverage/sncf/stop_areas/${codeStation}/departures`,
        {
          headers: {
            Authorization: `${process.env.REACT_APP_API_KEY}`,
          },
        }
      )
      .then((apiResponse) => {
        const departures = apiResponse.data.departures.map((departure) => ({
          // For the delayed trains, the id is concataned with : ":RealTime:xxxx" : we juste want the id
          id: departure.links[1].id.split(':RealTime')[0],
          // Train type (TER, TGV, etc.)
          transportationMode: departure.display_informations.network,
          // Train number
          trainNumber: departure.display_informations.headsign,
          // Planned departure time
          baseDepartureTime: parseUTCDate(
            departure.stop_date_time.base_departure_date_time
          ),
          // Real departure time with the delay
          realDepartureTime: parseUTCDate(
            departure.stop_date_time.departure_date_time
          ),
          // Destination (Remove the parentheses, e.g. : 'Nantes (Nantes)')
          destination: departure.display_informations.direction.split(' (')[0],
        }))

        setNextDepartures(departures)
      })
  }, [codeStation])

  // const [isTimeDisplayed, setIsTimeDisplayed] = useState(true)

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIsTimeDisplayed((prevIsTimeDisplayed) => !prevIsTimeDisplayed)
  //   }, 5000)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [])

  return (
    <div className="departures">
      {nextDepartures.map((departure, index) => (
        <div
          key={departure.id}
          className={`departure ${index % 2 ? '' : 'departure--light'}`}
        >
          <div className="departure__train-type">
            {departure.transportationMode}
          </div>
          <div className="departure__train-number">{departure.trainNumber}</div>
          <div className="departure__time">
            {departure.baseDepartureTime.getHours()}h
            {getFullMinutes(departure.baseDepartureTime)}
          </div>
          <div className="departure__delay">
            {calculateDelay(
              departure.baseDepartureTime,
              departure.realDepartureTime
            )}
          </div>
          <div className="departure__destination">{departure.destination}</div>
          <Stops idDeparture={departure.id} />
        </div>
      ))}
    </div>
  )
}

export default Departures
