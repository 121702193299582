import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import citiesStations from '../gares.json'

import TrainStations from './TrainStations'

function City() {
  // Read URL's params
  const { city } = useParams()
  // <Outlet /> renders childs route elements
  return (
    <div className="city">
      <h2 className="city__name">{city}</h2>
      <TrainStations stations={citiesStations[city]} />
      <Outlet />
    </div>
  )
}

export default City
