import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import { BrowserRouter } from 'react-router-dom'
import App from './App'

/**
 * On encadre toute l'application avec le routeur (disponible dans toutes l'application)
 * Le routeur est responsable de lire les URLs et affiche les bons composants en fonction des routes créees
 * Donne la possibilité grâce à <Link /> de rediriger utilisateur vers une certaine URL
 */
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
